
#navbar {
  box-shadow: 0 2px 4px 0 rgba(0,0,0,.2);
}

.nav-item {
  padding: 0px;
}

.navbar-collapse {
  padding: 0px;
}

.dropdown-toggle {
  padding: 0px;
}

.nav-reminder {
  padding-left: 10px;
  margin-top: -5px;
}

.ant-drawer-header {
  border-radius: 0;
}

div .ant-drawer-body {
  color: #fff;
  height: calc(100vh - 55px);
  padding: 0px;
}

.divStyle {
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background-color: transparent;
  transition: background-color 0.3s;
}

.divStyle:hover {
  background-color: #CC6600;
}

.sidebar-item {
  padding-left: 10px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;
  background-color: transparent;
  transition: background-color 0.3s;
  border-bottom: 1px solid white;
}

.sidebar-item:hover {
  background-color: #CC6600;
}

.basket-order-title {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  line-height: 19px;
  max-height: 40px;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  /* height: 40px; */
}

.basket-order-subtitle {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  line-height: 19px;
  /* fallback */
  max-height: 80px;
  /* fallback */
  -webkit-line-clamp: 4;
  /* number of lines to show */
  -webkit-box-orient: vertical;
  /* height: 80px; */
}