.footer-detail-board {
    background-color: black;
    color: white;
    border-radius: 0%;
}

.complaint-board {
    width: 200px;
}

.complaint-item {
    padding: 5px;
}

.complaint-item:hover {
    background-color: #23B14D;
    padding: 5px;
    margin: 0px;
    cursor: pointer;
}

.btn-consult {
    display: flex;
    align-items: center; 
    justify-content: center; 
    background-color: #FF7F00; 
    padding: 8px;
    border-radius: 50px; 
    width: 150px;
    color: black;
}

.btn-consult:hover {
    background-color: #CC6600;
    cursor: pointer;
    color: white;
}