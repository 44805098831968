.react-player-wrapper {
  position: relative;
  /* padding-top: 56.25%; */
  /* 16:9 Aspect Ratio (divide 9 by 16 = 0.5625) */
}

.react-player {
  /* position: absolute; */
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}